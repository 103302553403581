import axios from 'axios';

const getEUMessage = () => {
  return new Promise((resolve) => {
    axios
      .get('https://vn.demo.synamedia.co.uk/v1/getEUMessage')
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => resolve(''));
  });
};

export { getEUMessage };
